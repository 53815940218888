import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'twin.macro'
import Img from 'gatsby-image'
import get from 'lodash/get'

import text from '@utils/text'

import TracklineWrapper from '@objects/tracklineWrapper'
import Tag from '@objects/tag'
import Container from '@objects/container'
import Headline from '@objects/headline'
import breakpoint from '@utils/breakpoint'

const StyledAuthorImageWrapper = styled.div.attrs({
  className: 'rounded-full overflow-hidden mr-2 mb-4 md:mr-auto',
})`
  height: 40px;
  width: 40px;
  max-width: 60px;
  ${breakpoint('md')`
      height: 60px;
      width: 60px;
  `}
`

const ArticleInfo = styled.div`
  background: #f0f3f4;
  ${tw`
    pt-5
    md:py-14
    relative
    order-2
    md:order-1
    flex
    flex-col
    w-8/12
  `}

  :before {
    content: '';
    background: ${({ theme }) => get(theme, `colors['gray-light']['300']`)};
    height: 100%;
    width: 80vw;
    position: absolute;
    left: calc(-50vw + 50%);
    top: 0;
    z-index: -1;
    ${tw`
    hidden
    absolute
    md:block
    `}
  }

  .inner {
    margin: auto;
    display: inline-block;
  }
`
const ArticleMeta = styled.div.attrs({
  className:
    'relative flex mt-7 text-left w-full md:-top-7 md:flex-col md:mt-auto md:pb-10',
})`
  margin: auto;
  ${text('metalg')}
`

const StyledAuthorStageWrapper = styled.div.attrs({
  className: 'relative mt-8.5 md:mt-0',
})`
  margin-bottom: 90px;

  ${breakpoint('md')`
    margin-bottom: initial;
  `}

  &:before {
    content: '';
    background: ${({ theme }) => get(theme, `colors['gray-light']['300']`)};
    ${tw`block md:hidden absolute top-0 left-0 w-full h-full`}
  }
`

const StyledTracklineWrapper = styled(TracklineWrapper)`
  &.topbottom {
    &:before {
      z-index: 10;
      width: calc(100vw - 26px);
      ${breakpoint('md')`
       width: calc(50vw + (60px + 32px)*3 );
      `}
    }
    .firstcircle {
      left: calc(100vw - 26px);
      ${breakpoint('md')`
        left: calc(50vw + (60px + 32px)*3 );
      `}
    }

    &:after {
      width: ${`calc(50vw - (60px + 32px)*3 - 28px);`};
      ${tw`hidden md:block`};
    }
    .secondcircle {
      left: calc(50vw + (60px + 32px) * 3);
      ${tw`hidden md:block`}
    }
  }
`

function AuthorStage({ className, articleInfo }) {
  return (
    <StyledAuthorStageWrapper
      className={`${className} w-full`}
      data-testid="author"
    >
      <StyledTracklineWrapper
        topbottom={true}
        className={className}
        firstcircle="fullvert"
        topColor="red"
        secondcircle="halfvert"
        bottomColor="yellow"
      >
        <Container
          className={`flex flex-col md:flex-row bg-gray-light-300`}
          nopadding
          content
        >
          <ArticleInfo>
            <div className={'inner w-full'}>
              {articleInfo.tag && (
                <Tag className={'mb-7'} size={'xl'}>
                  {articleInfo.tag.name}
                </Tag>
              )}
              <Headline level={1} ariaLabel={articleInfo.headline}>
                {articleInfo.headline}
              </Headline>
            </div>
          </ArticleInfo>
        </Container>
      </StyledTracklineWrapper>

      {
        <Container className={`flex flex-col md:flex-row `} nopadding content>
          <ArticleMeta>
            {articleInfo.author?.image?.fluid && (
              <StyledAuthorImageWrapper>
                <Img
                  fluid={{
                    ...articleInfo.author.image.fluid,
                    media: `(min-width: 0px)`,
                  }}
                  loading="eager"
                  alt={articleInfo.author.image.description}
                />
              </StyledAuthorImageWrapper>
            )}
            <div>
              {articleInfo.author?.name && (
                <>
                  <span>{articleInfo.author.name}</span>
                  {articleInfo.author.date && (
                    <>
                      <br className={'block md:hidden'} />
                      <span className="hidden md:inline">, </span>
                    </>
                  )}
                </>
              )}
              {articleInfo.author.date}
            </div>
          </ArticleMeta>
        </Container>
      }
    </StyledAuthorStageWrapper>
  )
}

AuthorStage.propTypes = {
  className: PropTypes.string,
  articleInfo: PropTypes.shape({
    headline: PropTypes.string,
    author: PropTypes.shape({
      link: PropTypes.string,
      name: PropTypes.string,
      image: PropTypes.object,
      href: PropTypes.string,
      date: PropTypes.string,
    }),

    tag: PropTypes.shape({
      name: PropTypes.string,
      to: PropTypes.string,
    }),
  }),
}

export default AuthorStage
