import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Parallax as ScrollParallax } from 'react-scroll-parallax'

function Parallax({ className, children, fixed, min, aspectRatio, ...props }) {
  const CompRef = useRef(null)
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    const img = CompRef.current.querySelector('.gatsby-image-wrapper')
    let height = Math.max(min, img?.clientHeight)

    if (initialized) {
      img.style.height = 'auto'
      height = Math.max(min, img?.clientHeight)
    }

    if (img && !fixed) {
      CompRef.current.style.height = `${height}px`
      img.style.height = `${height + 80}px`
      img.style.marginTop = '-20px'
      setInitialized(true)
    }
  }, [aspectRatio])

  return (
    <div data-testid={'parallax'} ref={CompRef}>
      <ScrollParallax
        className={clsx(className, 'overflow-hidden h-full')}
        y={['-80px', '0px']}
        {...props}
      >
        {children}
      </ScrollParallax>
    </div>
  )
}

Parallax.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  fixed: PropTypes.bool,
  min: PropTypes.number,
  aspectRatio: PropTypes.number,
}

Parallax.defaultProps = {
  min: 0,
}

export default Parallax
