import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Headline from '@objects/headline'
import { TeaserCopy } from '@objects/copy'
import Button from '@objects/button'

function DefaultCard({ className, headline, copy, button, truncate }) {
  return (
    <div
      className={clsx(className, 'flex flex-col justify-start items-start')}
      data-testid="default"
    >
      <Headline level={1} className="mb-4 w-full" ariaLabel={headline}>
        {headline}
      </Headline>
      <TeaserCopy
        className="mb-7 w-full"
        noHrefArrow={true}
        truncate={truncate}
        html={copy}
      />
      {button && (
        <Button
          className={'btn-large'}
          to={button.to}
          href={button.href}
          link={button.link}
        >
          {button.label}
        </Button>
      )}
    </div>
  )
}

DefaultCard.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.string,
  copy: PropTypes.string,
  truncate: PropTypes.number,
  button: PropTypes.shape({
    label: PropTypes.string,
    to: PropTypes.string,
    link: PropTypes.string,
    href: PropTypes.string,
  }),
}

export default DefaultCard
