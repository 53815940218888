import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import clsx from 'clsx'
import get from 'lodash/get'

import Circle3pxT from '@static/img/tracklines/circles/circle-3px-T.inline.svg'
import Circle3pxHalfVert from '@static/img/tracklines/circles/circle-3px-halfline-vertical.inline.svg'
import Circle3pxFullVert from '@static/img/tracklines/circles/circle-3px-fullline-vertical.inline.svg'
import Circle3pxFullHori from '@static/img/tracklines/circles/circle-3px-fullline-horizontal.inline.svg'

import Circle5pxT from '@static/img/tracklines/circles/circle-3px-T.inline.svg'
import Circle5pxHalfVert from '@static/img/tracklines/circles/circle-5px-halfline-vertical.inline.svg'
import Circle5pxFullVert from '@static/img/tracklines/circles/circle-5px-fullline-vertical.inline.svg'
import Circle5pxFullHori from '@static/img/tracklines/circles/circle-5px-fullline-horizontal.inline.svg'

export const CircleTypes = ['t', 'halfvert', 'fullvert', 'fullhori']

function TracklineWrapper({
  children,
  className,
  topbottom,
  rounded,
  firstcircle,
  secondcircle,
  thick,
  gap,
  gapcontent,
  color,
  topColor,
  bottomColor,
  gapLarge,
}) {
  function renderFirstCircle(type, first = false) {
    if (thick) {
      switch (type) {
        case CircleTypes[0]:
          return (
            <Circle3pxT
              data-testid={CircleTypes[0]}
              className={first ? 'firstcircle' : 'secondcircle'}
            />
          )
        case CircleTypes[1]:
          return (
            <Circle3pxHalfVert
              data-testid={CircleTypes[1]}
              className={first ? 'firstcircle' : 'secondcircle'}
            />
          )
        case CircleTypes[2]:
          return (
            <Circle3pxFullVert
              data-testid={CircleTypes[2]}
              className={first ? 'firstcircle' : 'secondcircle'}
            />
          )
        case CircleTypes[3]:
          return (
            <Circle3pxFullHori
              data-testid={CircleTypes[3]}
              className={first ? 'firstcircle' : 'secondcircle'}
            />
          )
        default:
          return (
            <Circle3pxHalfVert
              className={first ? 'firstcircle' : 'secondcircle'}
            />
          )
      }
    } else {
      switch (type) {
        case CircleTypes[0]:
          return (
            <Circle5pxT
              data-testid={CircleTypes[0]}
              className={first ? 'firstcircle' : 'secondcircle'}
            />
          )
        case CircleTypes[1]:
          return (
            <Circle5pxHalfVert
              data-testid={CircleTypes[1]}
              className={first ? 'firstcircle' : 'secondcircle'}
            />
          )
        case CircleTypes[2]:
          return (
            <Circle5pxFullVert
              data-testid={CircleTypes[2]}
              className={first ? 'firstcircle' : 'secondcircle'}
            />
          )
        case CircleTypes[3]:
          return (
            <Circle5pxFullHori
              data-testid={CircleTypes[3]}
              className={first ? 'firstcircle' : 'secondcircle'}
            />
          )
        default:
          return (
            <Circle5pxHalfVert
              className={first ? 'firstcircle' : 'secondcircle'}
            />
          )
      }
    }
  }
  return (
    <div
      className={clsx(className, {
        topbottom: topbottom,
        rounded: rounded,
        hasgapcontent: rounded && !!gapcontent,
        gaptop: gap === 'top' || !gap,
        gapbottom: gap === 'bottom',
        gaptopleft: gap === 'topleft',
        gaptopright: gap === 'topright',
        gapbottomleft: gap === 'bottomleft',
        gapbottomright: gap === 'bottomright',
        gaplarge: gapLarge,
      })}
      aria-hidden="true"
    >
      {rounded && gapcontent && (
        <div className="gapcontent flex flex-row items-center justify-center">
          {gapcontent}
        </div>
      )}
      {renderFirstCircle(firstcircle, true)}
      {renderFirstCircle(secondcircle, false)}
      {children}
    </div>
  )
}

TracklineWrapper.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  topbottom: PropTypes.bool,
  rounded: PropTypes.bool,
  firstcircle: PropTypes.oneOf(CircleTypes),
  secondcircle: PropTypes.oneOf(CircleTypes),
  thick: PropTypes.bool,
  gapLarge: PropTypes.bool,
  gapcontent: PropTypes.any,
  gap: PropTypes.oneOf([
    'top',
    'bottom',
    'topleft',
    'topright',
    'bottomleft',
    'bottomright',
  ]),
  color: PropTypes.string,
  topColor: PropTypes.string,
  bottomColor: PropTypes.string,
}

const styleProps = {
  circleDim: { medium: '18px', thick: '28px' },
  borderDim: { medium: '3px', thick: '5px' },
  getCircleDim: (thick) =>
    thick ? styleProps.circleDim.thick : styleProps.circleDim.medium,
  getBorderDim: (thick) =>
    thick ? styleProps.borderDim.thick : styleProps.borderDim.medium,
  roundedBorderCornerDistance: (modifier) => {
    return `calc(100% - 80px${modifier ? ` + ${modifier}` : ''})`
  },
}

export default styled(TracklineWrapper)`
  .firstcircle,
  .secondcircle {
    z-index: 10;
    position: absolute;
    width: ${({ thick }) => styleProps.getCircleDim(thick)};
    height: ${({ thick }) => styleProps.getCircleDim(thick)};
  }

  &.topbottom {
    width: 100%;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0;
      height: ${({ thick }) => styleProps.getBorderDim(thick)};
      width: 80%;
      transform: translateY(-50%);

      background-color: ${({ theme, topColor }) =>
        get(theme, `colors.${topColor}.default`) ||
        theme.colors.yellow.default};
      z-index: 1;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0px;
      right: 0;
      height: ${({ thick }) => styleProps.getBorderDim(thick)};
      width: 80%;
      transform: translateY(50%);

      background-color: ${({ theme, bottomColor }) => {
        return (
          get(theme, `colors.${bottomColor}.default`) ||
          theme.colors.red.default
        )
      }};
    }

    .firstcircle {
      top: 0;
      left: 80%;
      transform: translate(-50%, -50%);

      circle,
      line {
        stroke: ${({ theme, topColor }) =>
          get(theme, `colors.${topColor}.default`) ||
          theme.colors.yellow.default};
      }
    }

    .secondcircle {
      bottom: 0;
      right: 80%;
      transform: translate(50%, 50%);

      circle,
      line {
        stroke: ${({ theme, bottomColor }) =>
          get(theme, `colors.${bottomColor}.default`) ||
          theme.colors.red.default};
      }
    }
  }

  &.rounded {
    position: relative;

    border: ${({ thick }) => styleProps.getBorderDim(thick)} solid
      ${({ theme, color }) =>
        get(theme, `colors.${color}`) || theme.colors.blue.default};
    border-radius: 16px;

    &:before,
    &:after {
      pointer-events: none;
      z-index: 25;
      content: '';
      position: absolute;
    }

    .gapcontent {
      position: absolute;
      text-align: center;
    }

    .firstcircle,
    .secondcircle {
      circle,
      line {
        stroke: ${({ theme, color }) =>
          get(theme, `colors.${color}.default`) || theme.colors.blue.default};
      }
    }

    &.gaptop,
    &.gapbottom {
      &:after,
      &:before {
        width: 40%;
        height: 100%;
      }

      .firstcircle {
        left: 40%;
        transform: translateX(-50%);
      }

      .secondcircle {
        right: 40%;
        transform: translateX(50%);
      }

      &.hasgapcontent {
        &:after,
        &:before {
          width: 35%;
        }

        &.gaplarge {
          &:after,
          &:before {
            width: 17.5%;
          }
        }

        .firstcircle {
          left: calc(35% + ${({ thick }) => styleProps.getBorderDim(thick)});
        }
        .secondcircle {
          right: calc(35% + ${({ thick }) => styleProps.getBorderDim(thick)});
        }

        &.gaplarge {
          .firstcircle {
            left: calc(
              17.5% + ${({ thick }) => styleProps.getBorderDim(thick)}
            );
          }
          .secondcircle {
            right: calc(
              17.5% + ${({ thick }) => styleProps.getBorderDim(thick)}
            );
          }
        }
      }

      .gapcontent {
        left: 50%;
        width: 28%;
      }

      &.gaplarge {
        .gapcontent {
          width: 56%;
        }
      }
    }

    &.gaptop {
      border-top: none;

      &:before,
      &:after {
        top: 0;
        border-top: ${({ thick }) => styleProps.getBorderDim(thick)} solid
          ${({ theme, color }) =>
            get(theme, `colors.${color}`) || theme.colors.blue.default};
      }
      &:before {
        border-radius: 16px 0px 0px 16px;
        border-left: ${({ thick }) => styleProps.getBorderDim(thick)} solid
          ${({ theme, color }) =>
            get(theme, `colors.${color}`) || theme.colors.blue.default};
        left: -${({ thick }) => styleProps.getBorderDim(thick)};
      }
      &:after {
        border-radius: 0px 16px 16px 0px;
        border-right: ${({ thick }) => styleProps.getBorderDim(thick)} solid
          ${({ theme, color }) =>
            get(theme, `colors.${color}`) || theme.colors.blue.default};
        right: -${({ thick }) => styleProps.getBorderDim(thick)};
      }

      .firstcircle,
      .secondcircle {
        top: ${({ thick }) => (thick ? '-11px' : '-7px')};
      }

      .gapcontent {
        top: 3px;
        transform: translate(-50%, -50%);
      }
    }

    &.gaptopleft {
      border-top: none;
      border-left: none;

      &:before {
        right: -${({ thick }) => styleProps.getBorderDim(thick)};
        width: ${() => styleProps.roundedBorderCornerDistance()};
        height: calc(100% + ${({ thick }) => styleProps.getBorderDim(thick)});
        border-radius: 0px 16px 16px 0px;
        border-top: ${({ thick }) => styleProps.getBorderDim(thick)} solid
          ${({ theme, color }) =>
            get(theme, `colors.${color}`) || theme.colors.blue.default};
        border-right: ${({ thick }) => styleProps.getBorderDim(thick)} solid
          ${({ theme, color }) =>
            get(theme, `colors.${color}`) || theme.colors.blue.default};
        top: -${({ thick }) => styleProps.getBorderDim(thick)};
      }
      &:after {
        bottom: -${({ thick }) => styleProps.getBorderDim(thick)};
        height: ${() => styleProps.roundedBorderCornerDistance()};
        width: calc(100% + ${({ thick }) => styleProps.getBorderDim(thick)});
        border-radius: 0px 16px 16px 16px;
        border-left: ${({ thick }) => styleProps.getBorderDim(thick)} solid
          ${({ theme, color }) =>
            get(theme, `colors.${color}`) || theme.colors.blue.default};
        border-bottom: ${({ thick }) => styleProps.getBorderDim(thick)} solid
          ${({ theme, color }) =>
            get(theme, `colors.${color}`) || theme.colors.blue.default};
        left: -${({ thick }) => styleProps.getBorderDim(thick)};
      }

      .firstcircle {
        right: ${() => styleProps.roundedBorderCornerDistance()};
        top: ${({ thick }) => (thick ? '-2px' : '-1px')};
        transform: translate(49%, -50%);
      }

      .secondcircle {
        bottom: ${() => styleProps.roundedBorderCornerDistance()};
        left: ${({ thick }) => (thick ? '-2px' : '-1px')};
        transform: translate(-49%, 50%);
      }

      .gapcontent {
        display: none;
      }
    }

    &.gaptopright {
      border-top: none;
      border-right: none;

      &:before {
        left: -${({ thick }) => styleProps.getBorderDim(thick)};
        width: ${() => styleProps.roundedBorderCornerDistance()};
        height: calc(100% + ${({ thick }) => styleProps.getBorderDim(thick)});
        border-radius: 16px 0px 0px 16px;
        border-top: ${({ thick }) => styleProps.getBorderDim(thick)} solid
          ${({ theme, color }) =>
            get(theme, `colors.${color}`) || theme.colors.blue.default};
        border-left: ${({ thick }) => styleProps.getBorderDim(thick)} solid
          ${({ theme, color }) =>
            get(theme, `colors.${color}`) || theme.colors.blue.default};
        top: -${({ thick }) => styleProps.getBorderDim(thick)};
      }
      &:after {
        bottom: -${({ thick }) => styleProps.getBorderDim(thick)};
        left: -${({ thick }) => styleProps.getBorderDim(thick)};
        height: ${() => styleProps.roundedBorderCornerDistance()};
        width: calc(100% + ${({ thick }) => styleProps.getBorderDim(thick)});
        border-radius: 16px 0px 16px 16px;
        border-right: ${({ thick }) => styleProps.getBorderDim(thick)} solid
          ${({ theme, color }) =>
            get(theme, `colors.${color}`) || theme.colors.blue.default};
        border-bottom: ${({ thick }) => styleProps.getBorderDim(thick)} solid
          ${({ theme, color }) =>
            get(theme, `colors.${color}`) || theme.colors.blue.default};
      }

      .firstcircle {
        left: ${({ thick }) =>
          styleProps.roundedBorderCornerDistance(
            styleProps.getBorderDim(thick)
          )};
        top: ${({ thick }) => (thick ? '-2px' : '-1px')};
        transform: translate(-49%, -50%);
      }

      .secondcircle {
        bottom: ${({ thick }) =>
          styleProps.roundedBorderCornerDistance(
            styleProps.getBorderDim(thick)
          )};
        right: ${({ thick }) => (thick ? '2px' : '1px')};
        transform: translate(49%, 50%);
      }

      .gapcontent {
        display: none;
      }
    }

    &.gapbottom {
      border-bottom: none;

      &:before,
      &:after {
        bottom: 0;
        border-bottom: ${({ thick }) => styleProps.getBorderDim(thick)} solid
          ${({ theme, color }) =>
            get(theme, `colors.${color}`) || theme.colors.blue.default};
      }
      &:before {
        border-radius: 16px 0px 0px 16px;
        border-left: ${({ thick }) => styleProps.getBorderDim(thick)} solid
          ${({ theme, color }) =>
            get(theme, `colors.${color}`) || theme.colors.blue.default};
        left: -${({ thick }) => styleProps.getBorderDim(thick)};
      }
      &:after {
        border-radius: 0px 16px 16px 0px;
        border-right: ${({ thick }) => styleProps.getBorderDim(thick)} solid
          ${({ theme, color }) =>
            get(theme, `colors.${color}`) || theme.colors.blue.default};
        right: -${({ thick }) => styleProps.getBorderDim(thick)};
      }

      .firstcircle,
      .secondcircle {
        bottom: ${({ thick }) => (thick ? '-11px' : '-7px')};
      }

      .gapcontent {
        bottom: 3px;
        transform: translate(-50%, 50%);
      }
    }

    &.gapbottomleft {
      border-bottom: none;
      border-left: none;

      &:before {
        left: 0;
        height: ${() => styleProps.roundedBorderCornerDistance()};
        width: calc(100% + ${({ thick }) => styleProps.getBorderDim(thick)});
        border-radius: 16px 16px 0px 0px;
        border-top: ${({ thick }) => styleProps.getBorderDim(thick)} solid
          ${({ theme, color }) =>
            get(theme, `colors.${color}`) || theme.colors.blue.default};
        border-left: ${({ thick }) => styleProps.getBorderDim(thick)} solid
          ${({ theme, color }) =>
            get(theme, `colors.${color}`) || theme.colors.blue.default};
        top: -${({ thick }) => styleProps.getBorderDim(thick)};
      }
      &:after {
        bottom: 0;
        width: ${() => styleProps.roundedBorderCornerDistance()};
        height: calc(100% + ${({ thick }) => styleProps.getBorderDim(thick)});
        border-radius: 0px 16px 16px 0px;
        border-right: ${({ thick }) => styleProps.getBorderDim(thick)} solid
          ${({ theme, color }) =>
            get(theme, `colors.${color}`) || theme.colors.blue.default};
        border-bottom: ${({ thick }) => styleProps.getBorderDim(thick)} solid
          ${({ theme, color }) =>
            get(theme, `colors.${color}`) || theme.colors.blue.default};
        right: -${({ thick }) => styleProps.getBorderDim(thick)};
      }

      .firstcircle {
        top: ${() => styleProps.roundedBorderCornerDistance()};
        left: ${({ thick }) => (thick ? '2px' : '1px')};
        transform: translate(-49%, -50%);
      }

      .secondcircle {
        right: ${({ thick }) =>
          styleProps.roundedBorderCornerDistance(
            styleProps.getBorderDim(thick)
          )};
        bottom: ${({ thick }) => (thick ? '-11px' : '-7px')};
        transform: translateX(50%);
      }

      .gapcontent {
        display: none;
      }
    }

    &.gapbottomright {
      border-bottom: none;
      border-right: none;

      &:before {
        right: 0;
        height: ${() => styleProps.roundedBorderCornerDistance()};
        width: calc(100% + ${({ thick }) => styleProps.getBorderDim(thick)});
        border-radius: 16px 16px 0px 0px;
        border-top: ${({ thick }) => styleProps.getBorderDim(thick)} solid
          ${({ theme, color }) =>
            get(theme, `colors.${color}`) || theme.colors.blue.default};
        border-right: ${({ thick }) => styleProps.getBorderDim(thick)} solid
          ${({ theme, color }) =>
            get(theme, `colors.${color}`) || theme.colors.blue.default};
        top: -${({ thick }) => styleProps.getBorderDim(thick)};
      }
      &:after {
        bottom: 0;
        width: ${() => styleProps.roundedBorderCornerDistance()};
        height: calc(100% + ${({ thick }) => styleProps.getBorderDim(thick)});
        border-radius: 16px 16px 0px 16px;
        border-left: ${({ thick }) => styleProps.getBorderDim(thick)} solid
          ${({ theme, color }) =>
            get(theme, `colors.${color}`) || theme.colors.blue.default};
        border-bottom: ${({ thick }) => styleProps.getBorderDim(thick)} solid
          ${({ theme, color }) =>
            get(theme, `colors.${color}`) || theme.colors.blue.default};
        left: -${({ thick }) => styleProps.getBorderDim(thick)};
      }

      .firstcircle {
        top: ${() => styleProps.roundedBorderCornerDistance()};
        right: ${({ thick }) => (thick ? '2px' : '1px')};
        transform: translate(49%, -50%);
      }

      .secondcircle {
        left: ${() => styleProps.roundedBorderCornerDistance()};
        bottom: ${({ thick }) => (thick ? '-11px' : '-7px')};
        transform: translateX(-50%);
      }

      .gapcontent {
        display: none;
      }
    }
  }
`
