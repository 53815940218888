import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import text from '@utils/text'

import Headline from '@objects/headline'

function Tag({ className, to, filter, children, headline, size }) {
  if (headline) {
    children = (
      <Headline level={headline} ariaLabel={children}>
        {children}
      </Headline>
    )
  }
  const filterParam = filter
    ? `#articlefilter=${filter.toLowerCase().replace(/ /g, '')}`
    : ''

  return (
    <div className={className}>
      {(to && <Link to={`${to}${filterParam}`}>{children}</Link>) || (
        <>{children}</>
      )}
    </div>
  )
}

Tag.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string,
  filter: PropTypes.string,
  children: PropTypes.any,
  size: PropTypes.oneOf(['md', 'lg', 'xl']),
  headline: PropTypes.number,
}

export default styled(Tag)`
  ${(props) => text(`tag${props.size || 'md'}`)}
`
