import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import InfoCardStage from './types/infocardstage'
import AuthorImageStage from './types/authorimagestage'
import AuthorStage from './types/authorstage'
import EventStage from './types/eventstage'
import HeadlineImageStage from './types/headlineimagestage'

function Stage({
  className,
  type,
  infocard,
  image,
  video,
  videoPoster,
  article,
  disruptor,
  options,
  fixed,
}) {
  function renderType() {
    switch (type) {
      case 'infocard':
        return (
          <InfoCardStage
            infocard={infocard}
            image={image}
            video={video}
            videoPoster={videoPoster}
            disruptor={disruptor}
            options={options}
          />
        )
      case 'authorimage':
        return (
          <AuthorImageStage
            infocard={infocard}
            image={image}
            articleInfo={article}
          />
        )
      case 'author':
        return <AuthorStage articleInfo={article} />
      case 'event':
        return <EventStage image={image} eventInfo={article} />
      case 'headlineimage':
        return (
          <HeadlineImageStage
            image={image}
            articleInfo={article}
            fixed={fixed}
          />
        )
      default:
        return <InfoCardStage infocard={infocard} image={image} />
    }
  }
  return (
    <div
      role="region"
      aria-label="Stage"
      className={clsx(className, 'w-full relative')}
    >
      {renderType()}
    </div>
  )
}

Stage.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf([
    'headlineimage',
    'infocard',
    'author',
    'authorimage',
    'event',
  ]),
  infocard: PropTypes.object,
  image: PropTypes.object,
  video: PropTypes.object,
  videoPoster: PropTypes.object,
  article: PropTypes.object,
  disruptor: PropTypes.object,
  options: PropTypes.object,
  fixed: PropTypes.bool,
}

export default Stage
