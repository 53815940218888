import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'twin.macro'
import Img from 'gatsby-image'

import text from '@utils/text'

import Tag from '@objects/tag'
import Container from '@objects/container'
import Headline from '@objects/headline'

import Circle3pxFullVert from '@static/img/tracklines/circles/circle-3px-fullline-vertical.inline.svg'
import Circle3pxHalfVert from '@static/img/tracklines/circles/circle-3px-halfline-vertical.inline.svg'
import breakpoint from '@utils/breakpoint'
import Parallax from '@objects/parallax'

const StyledAuthorImageWrapper = styled.div`
  height: 40px;
  width: 40px;
  max-width: 60px;
  ${breakpoint('md')`
      height: 60px;
      width: 60px;
  `}
  ${tw`
      rounded-full
      overflow-hidden
      mb-4
      md:ml-auto
      mr-2
      md:mr-0
    `}
`

const StyledImageWrapper = styled.div`
    margin-left: -16px;
    margin-right: -16px;
    width: 100vw;
  ${tw`
    order-1
    z-0
    relative
  `}

  ${breakpoint('md')`
    max-width: 520px;
    margin-left: initial;
    width: 100%;
  `}
  ${tw`
    md:order-2
  `}
`
const ArticleInfo = styled.div`
  ${tw`
    pt-5
    md:pr-7
    md:pt-11
    relative
    order-2
    md:order-1
    flex
    flex-col
    flex-grow
  `}
  :before {
    content: '';
    background: #f0f3f4;
    height: 70%;
    width: 55vw;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    ${tw`hidden md:block absolute`}
  }
`
const ArticleMeta = styled.div`
  ${tw`
  flex
  mt-7
  md:flex-col
  md:text-right
  md:mt-auto
  md:pb-10
  `}
  ${text('metalg')}
`

function AuthorImageStage({ className, image, articleInfo }) {
  return (
    <div className={`${className} ${tw`w-full`}`} data-testid="author-image">
      <div className={'hidden md:block topline'}>
        {/* graphic top */}
        <Circle3pxFullVert className={'topcircle'} />
      </div>
      <Container className={`flex flex-col md:flex-row `} nopadding>
        <ArticleInfo>
          {articleInfo.tag && (
            <Tag className={'mb-7'} size={'xl'}>
              {articleInfo.tag.name}
            </Tag>
          )}
          <Headline level={1} ariaLabel={articleInfo.headline}>
            {articleInfo.headline}
          </Headline>

          {
            <ArticleMeta>
              {articleInfo.author.image?.fluid && (
                <StyledAuthorImageWrapper>
                  <Img
                    fluid={{
                      ...articleInfo.author.image.fluid,
                      media: `(min-width: 0px)`,
                    }}
                    loading="eager"
                    alt={articleInfo.author.image.description}
                  />
                </StyledAuthorImageWrapper>
              )}
              <div>
                {articleInfo.author?.name && (
                  <>
                    <span>{articleInfo.author.name}</span>
                    {articleInfo.author.date && (
                      <>
                        <br className={'block md:hidden'} />
                        <span className="hidden md:inline">, </span>
                      </>
                    )}
                  </>
                )}
                {articleInfo.author.date}
              </div>
            </ArticleMeta>
          }
        </ArticleInfo>
        {image?.fluid && (
          <StyledImageWrapper>
            <Parallax>
              <Img
                fluid={{ ...image.fluid, media: `(min-width: 0px)` }}
                alt={image.description}
                imgStyle={{ objectPosition: 'center', objectFit: 'contain' }}
                loading="eager"
                backgroundColor={true}
              />
            </Parallax>
            <div className={'block md:hidden bottomline'}>
              <Circle3pxHalfVert className={'bottomcircle'} />
            </div>
          </StyledImageWrapper>
        )}
      </Container>
      <div className={'bottomline-wrapper'}>
        <div className={'hidden md:block bottomline'}>
          <Circle3pxHalfVert className={'bottomcircle'} />
        </div>
      </div>
    </div>
  )
}

AuthorImageStage.propTypes = {
  className: PropTypes.string,
  articleInfo: PropTypes.shape({
    headline: PropTypes.string,
    author: PropTypes.shape({
      link: PropTypes.string,
      name: PropTypes.string,
      image: PropTypes.object,
      date: PropTypes.string,
      href: PropTypes.string,
    }),
    tag: PropTypes.shape({
      name: PropTypes.string,
      to: PropTypes.string,
    }),
  }),
  image: PropTypes.object,
}

export default styled(AuthorImageStage).attrs({
  className: 'relative mb-13 md:mb-8.5',
})`
  .topline {
    position: absolute;
    top: 0;
    right: calc(50% - 260px);
    width: calc(50vw + 260px);
    height: 3px;
    background: ${({ theme }) => theme.colors.red.default};
    z-index: 10;

    .topcircle {
      position: absolute;
      right: 0;
      top: -10px;
      height: 20px;
      width: 20px;
      line,
      circle {
        stroke: ${({ theme }) => theme.colors.red.default};
      }
    }
  }

  .bottomline-wrapper {
    position: absolute;
    height: 20px;
    width: 100vw;
    overflow: hidden;
    bottom: -10px;

    .bottomline {
      bottom: 10px;
    }
  }

  .bottomline {
    position: absolute;
    bottom: 0;
    right: 16px;
    width: calc(100vw - 16px);
    height: 3px;
    background: ${({ theme }) => theme.colors.yellow.default};
    z-index: 10;

    .bottomcircle {
      position: absolute;
      right: 0;
      bottom: -10px;
      height: 20px;
      width: 20px;
      line,
      circle {
        stroke: ${({ theme }) => theme.colors.yellow.default};
      }
    }
  }

  ${breakpoint('md')`

    .bottomline {
      left: calc( 50% + 260px );
      width: calc(50% - 260px);
      right: initial;

      .bottomcircle {
        right: initial;
        left: 0;
      }
    }
  `}
`
