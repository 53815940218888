import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import DefaultCard from './types/default'
import DateCard from './types/date'
import DateButtonCard from './types/datebutton'

function InfoCard({
  className,
  headline,
  copy,
  truncate,
  button,
  date,
  dateEnd,
  dateRelative,
  tag,
  href,
  location,
}) {
  function getType() {
    if (date && button) {
      return 'datebutton'
    }
    if (date) {
      return 'date'
    }
    return 'default'
  }

  function renderType() {
    switch (getType()) {
      case 'datebutton':
        return (
          <DateButtonCard
            truncate={truncate}
            headline={headline}
            copy={copy}
            button={button}
            date={date}
            dateEnd={dateEnd}
            dateRelative={dateRelative}
            tag={tag}
            location={location}
          />
        )
      case 'date':
        return (
          <DateCard
            truncate={truncate}
            headline={headline}
            copy={copy}
            date={date}
            tag={tag}
            href={href}
          />
        )
      default:
        return (
          <DefaultCard
            truncate={truncate}
            headline={headline}
            copy={copy}
            button={button}
          />
        )
    }
  }

  return (
    <div className={clsx('p-7 rounded-sm bg-white bg-opacity-85', className)}>
      {renderType()}
    </div>
  )
}

InfoCard.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.string,
  copy: PropTypes.string,
  truncate: PropTypes.number,
  button: PropTypes.object,
  href: PropTypes.string,
  date: PropTypes.string,
  dateEnd: PropTypes.string,
  dateRelative: PropTypes.bool,
  tag: PropTypes.shape({
    name: PropTypes.string,
    to: PropTypes.string,
    filter: PropTypes.string,
  }),
  location: PropTypes.string,
}

export default InfoCard
