import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'twin.macro'
import Img from 'gatsby-image'
import clsx from 'clsx'

import text from '@utils/text'

import Container from '@objects/container'
import Headline from '@objects/headline'

import Circle3pxFullVert from '@static/img/tracklines/circles/circle-3px-fullline-vertical.inline.svg'
import Circle3pxHalfVert from '@static/img/tracklines/circles/circle-3px-halfline-vertical.inline.svg'
import breakpoint from '@utils/breakpoint'
import useDateFns from '@hooks/useDateFns'
import Parallax from '@objects/parallax'

const StyledImageWrapper = styled.div.attrs({
  className: 'order-1 z-0 relative md:order-2',
})`
  margin-left: -12px;
  margin-right: -12px;
  width: 100vw;
  ${breakpoint('md')`
    max-width: 520px;
    margin-left: initial;
    margin-right: initial;
    width: 100%;
  `}
`
const ArticleInfo = styled.div.attrs({
  className:
    'pt-5 md:pr-7 md:pt-11 relative order-2 md:order-1 flex flex-col flex-grow',
})`
  :before {
    content: '';
    background: #f0f3f4;
    height: 70%;
    width: 55vw;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    ${tw`hidden md:block absolute`}
  }

  &.no-image {
    &:before {
      height: 100%;
      width: 100vw;
      display: block;

      ${breakpoint('md')`
        height: 100%;
      `}
    }

    h1 {
      max-width: 430px;
      ${tw`mb-14`}
    }
  }
`

const ArticleMeta = styled.div.attrs({
  className: 'flex justify-between mb-4 md:mb-7',
})`
  max-width: 430px;
  ${text('metaxl')}
`

function EventStage({ className, image, eventInfo }) {
  const { title, dateTimeStart, shortLocation } = eventInfo
  const { formatDate } = useDateFns()

  return (
    <div
      className={clsx(className, [tw`w-full`, { 'no-image': !image?.fluid }])}
      data-testid="event-image"
    >
      <div
        className={clsx('md:visible topline', [{ invisible: image?.fluid }])}
      >
        {/* graphic top */}
        <Circle3pxFullVert className={'topcircle'} />
      </div>
      <Container className={`flex flex-col md:flex-row `} nopadding>
        <ArticleInfo className={clsx({ 'no-image': !image?.fluid })}>
          <ArticleMeta>
            {dateTimeStart && <div>{formatDate(dateTimeStart)}</div>}
            {shortLocation && <div>{shortLocation}</div>}
          </ArticleMeta>
          <Headline level={1} ariaLabel={title}>
            {title}
          </Headline>
        </ArticleInfo>
        {image?.fluid && (
          <StyledImageWrapper>
            <Parallax>
              <Img
                fluid={{ ...image.fluid, media: `(min-width: 0px)` }}
                alt={image.description}
                backgroundColor={true}
                loading="eager"
              />
            </Parallax>
            <div className={'block md:hidden bottomline'}>
              <Circle3pxHalfVert className={'bottomcircle'} />
            </div>
          </StyledImageWrapper>
        )}
      </Container>
      <div className={'bottomline-wrapper'}>
        <div className={'hidden md:block bottomline'}>
          <Circle3pxHalfVert className={'bottomcircle'} />
        </div>
      </div>
    </div>
  )
}

EventStage.propTypes = {
  className: PropTypes.string,
  eventInfo: PropTypes.shape({
    title: PropTypes.string,
    dateTimeStart: PropTypes.string,
    shortLocation: PropTypes.string,
  }),
  image: PropTypes.object,
}

export default styled(EventStage).attrs({
  className: 'relative mb-0 md:mb-8.5',
})`
  .topline {
    position: absolute;
    top: 0;
    right: 26px;
    width: calc(100vw - 26px);
    height: 3px;
    background: ${({ theme }) => theme.colors.red.default};
    z-index: 10;

    .topcircle {
      position: absolute;
      right: 0;
      top: -10px;
      height: 20px;
      width: 20px;
      line,
      circle {
        stroke: ${({ theme }) => theme.colors.red.default};
      }
    }
  }

  .bottomline-wrapper {
    position: absolute;
    height: 20px;
    width: 100vw;
    overflow: hidden;
    bottom: -10px;

    .bottomline {
      bottom: 10px;
    }
  }

  .bottomline {
    position: absolute;
    bottom: 0;
    right: 16px;
    width: calc(100vw - 16px);
    height: 3px;
    background: ${({ theme }) => theme.colors.yellow.default};
    z-index: 10;

    .bottomcircle {
      position: absolute;
      right: 0;
      bottom: -10px;
      height: 20px;
      width: 20px;
      line,
      circle {
        stroke: ${({ theme }) => theme.colors.yellow.default};
      }
    }
  }

  ${breakpoint('md')`

    .topline {
      right: calc(50% - 260px);
      width: calc(50vw + 260px);
    }

    .bottomline {
      left: calc( 50% + 260px );
      width: calc(50% - 260px);
      right: initial;

      .bottomcircle {
        right: initial;
        left: 0;
      }
    }
  `}

  &.no-image {
    ${tw`mb-0 mt-8.5  md:mt-0`}
  }
`
