import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { throttle } from 'lodash'
import { useLocation } from '@reach/router'

import InfoCard from '@objects/infoCard'
import Container from '@objects/container'
import Icon from '@objects/icon'
import Parallax from '@objects/parallax'
import Circle3pxHalfVert from '@static/img/tracklines/circles/circle-3px-halfline-vertical.inline.svg'
import breakpoint from '@utils/breakpoint'

const StyledStageWrapper = styled.div`
  scroll-snap-type: y mandatory;

  ${breakpoint('md')`
    padding-top: 76px;
   `}
  ${breakpoint('lg')`
    padding-top: initial;
   `}
`

const fadeInKeyframes = keyframes`
  0% {opacity: 0}
  100% {opacity: 1}
`

const StyledInfoCard = styled(InfoCard).attrs({
  className: `w-full
     top-0
     mt-4
     mx-auto
     p-0
     pb-7
     z-10
     transform
     lg:mt-11
     lg:left-0
     lg:absolute
     lg:w-8/12
     lg:left-0
     lg:translate-x-0
     lg:mx-7
     lg:p-7
     lg:w-6/12
     xl:w-6/12
     xl:-translate-x-10
     opacity-0`,
})`
  max-width: 460px;
  animation: ${fadeInKeyframes} 1s forwards linear 1.5s;
`

const StyledImageWrapper = styled.div.attrs({
  className: 'relative w-full z-0 order-1 lg:order-2',
})`
  scroll-snap-align: start;
  min-height: 375px;
`

const StyledVideoWrapper = styled.div.attrs({
  className: 'w-full z-0 order-1 lg:order-2 relative',
})`
  scroll-snap-align: start;
  max-height: 375px;
  width: 100vw;
  overflow: hidden;
  background-color: ${({ theme }) => {
    return theme.colors['gray-light'][650]
  }};
  ${breakpoint('lg')`
    max-height: 100%;
    height: calc(100vh - 116px);
    width: 100vw;
  `}
  video {
    width: 100vw;
    max-width: initial;
    display: block;
    max-height: initial;
    position: relative;
    z-index: 1;
    ${breakpoint('lg')`
      max-height: initial;
      height: auto;
      min-height: 100%;
      min-width: 100vw;
    `}
  }
`

const StyledPauseButton = styled.button.attrs({
  className: 'absolute bottom-8 right-8 cursor-pointer',
})`
  display: block;
  height: 54px;
  width: 54px;
  z-index: 999;
  font: inherit;
  padding: 0;
  outline: 0;
  border: 0;
  .pause-button-icon,
  .play-button-icon {
    fill: #ffffff;
    height: 54px;
    width: 54px;
  }
  &:focus {
    outline: 3px solid #008000;
  }
`

const BottomLine = styled.div`
  position: absolute;
  height: 20px;
  width: 100vw;
  display: none;
  bottom: 0;

  .bottomline {
    position: absolute;
    bottom: 0;
    right: 16px;
    width: calc(100vw - 16px);
    height: 3px;
    background: ${({ theme }) => theme.colors.yellow.default};
    z-index: 10;

    .bottomcircle {
      position: absolute;
      right: 0;
      bottom: -10px;
      height: 20px;
      width: 20px;
      line,
      circle {
        stroke: ${({ theme }) => theme.colors.yellow.default};
      }
    }
  }
  ${breakpoint('md')`
    display: block;
    .bottomline {
      left: calc( 50% + 260px );
      width: calc(50% - 260px);
      right: initial;

      .bottomcircle {
        right: initial;
        left: 0;
      }
    }
  `}
`

function handlePauseClick(setPauseState) {
  const video = window?.document?.querySelector('#home-video')
  if (video && video.paused) {
    video.play()
    setPauseState(false)
  } else if (video) {
    setPauseState(true)
    video.pause()
  }
  return
}

const PauseButton = () => {
  const [PauseState, setPauseState] = useState(false)

  return (
    <StyledPauseButton
      onClick={() => {
        handlePauseClick(setPauseState)
      }}
      aria-label="play pause toggle"
      aria-controls="home-video"
    >
      {(PauseState && <Icon name="Play" className={'play-button-icon'} />) || (
        <Icon name="Pause" className={'pause-button-icon'} />
      )}
    </StyledPauseButton>
  )
}

const scrolldownKeyframes = keyframes`
  0%, 30% {
    bottom: 26px;
  }
  0% {opacity: 0}
  10% {opacity: 1}
  90% {opacity: 1}
  100% {opacity: 0}
  70%, 100% {
    bottom: 8px;
  }
`

const ScrollDownIcon = styled.div.attrs({
  className: 'absolute hidden lg:block cursor-pointer',
})`
  height: 42px;
  width: 22px;
  border-radius: 11px;
  left: 50%;
  bottom: 35px;
  transform: translateX(-50%);
  border: white 2px solid;
  opacity: 1;
  transition: opacity 500ms linear;

  &:after {
    content: '';
    display: block;
    position: absolute;
    height: 6px;
    width: 6px;
    left: 50%;
    bottom: 8px;
    transform: translateX(-50%);
    background: white;
    border-radius: 9999px;
    animation: ${scrolldownKeyframes} 2s linear infinite;
  }

  &.scrolled {
    opacity: 0;
    cursor: auto;
  }
`
const DisruptorImage = styled.div`
  position: absolute;
  z-index: 99999;
  width: calc(134px * 1.075);
  height: calc(134px * 1.075);
  right: calc(16px - (0.05));
  bottom: calc(16px - (0.05 * 134px));
  opacity: 0;
  animation: ${fadeInKeyframes} 1s forwards linear 3s;

  ${breakpoint('lg')`
      width: calc(200px * 1.075);
      height: calc(200px * 1.075);
      bottom calc( 100px - (0.05 * 200px));
      right: calc(100px - (0.05 * 200px) + 10px);
    `}

  a {
    display: block;
    &:focus {
      outline-color: #fff;
    }
  }
`

function InfoCardStage({
  image,
  video,
  videoPoster,
  infocard,
  disruptor,
  options,
}) {
  const overrideAspectRatio =
    image?.desktopAspectRatio ?? image?.fluid?.aspectRatio

  const [aspectRatio, setAspectRatio] = useState(overrideAspectRatio ?? 0)
  const wrapperRef = useRef(null)
  const scrollButtonRef = useRef(null)
  const useImage = image && !video
  const showScrollIcon = !options?.hideScrollIcon
  const location = useLocation()

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (showScrollIcon) {
      window.addEventListener(
        'scroll',
        throttledScrollDownButtonOnScrollHandler
      )
      return () => {
        window.removeEventListener(
          'scroll',
          throttledScrollDownButtonOnScrollHandler
        )
      }
    }
  }, [])

  function handleResize() {
    setAspectRatio(window.innerWidth < 768 ? 1 : overrideAspectRatio)
  }

  const throttledScrollDownButtonOnScrollHandler = throttle(() => {
    return scrollDownButtonOnScrollHandler()
  }, 250)
  function scrollDownButtonOnScrollHandler() {
    if (window?.scrollY && showScrollIcon) {
      return scrollButtonRef?.current?.classList.add('scrolled')
    } else {
      return scrollButtonRef?.current?.classList.remove('scrolled')
    }
  }

  const scrollDownFn = () => {
    window.scrollTo({
      top:
        wrapperRef.current.offsetParent.offsetTop +
        wrapperRef.current.offsetHeight, // use Stage component parent offset top
      behavior: 'smooth',
    })
  }

  return (
    <StyledStageWrapper
      ref={wrapperRef}
      className="relative flex flex-col"
      data-testid="infocard"
    >
      <Container nopadding className={'order-2 lg:order-1'}>
        <StyledInfoCard {...infocard} />
      </Container>
      {useImage && (
        <StyledImageWrapper>
          <Parallax min={375}>
            <Img
              className="w-full"
              fluid={{ ...image.fluid, aspectRatio, media: `(min-width: 0px)` }}
              backgroundColor={true}
              imgStyle={{ objectPosition: 'top center' }}
              alt={image.description}
              loading="eager"
            />
          </Parallax>

          {disruptor?.link && disruptor?.fluid && (
            <DisruptorImage>
              <Link to={`${location.origin}${disruptor.link}`}>
                <Img
                  fluid={{ ...disruptor.fluid, media: `(min-width: 0px)` }}
                  alt={disruptor.description}
                  loading="eager"
                />
              </Link>
            </DisruptorImage>
          )}
          {options?.bottomline && (
            <BottomLine>
              <div className={'hidden md:block bottomline'}>
                <Circle3pxHalfVert className={'bottomcircle'} />
              </div>
            </BottomLine>
          )}
        </StyledImageWrapper>
      )}
      {video && (
        <StyledVideoWrapper>
          <video
            muted
            loop
            autoPlay
            id={'home-video'}
            poster={videoPoster?.file?.url + '?w=1920&q=80&fm=webp'}
            tabIndex={-1}
          >
            <source src={video?.file?.url} type={video?.file?.contentType} />
          </video>
          <PauseButton />
          {disruptor?.link && disruptor?.fluid && (
            <DisruptorImage>
              <Link to={disruptor.link}>
                <Img
                  fluid={{ ...disruptor.fluid, media: `(min-width: 0px)` }}
                  alt={disruptor.description}
                  loading="eager"
                />
              </Link>
            </DisruptorImage>
          )}
        </StyledVideoWrapper>
      )}
      {showScrollIcon && (
        <ScrollDownIcon
          ref={scrollButtonRef}
          onClick={() => {
            scrollDownFn()
          }}
        />
      )}
    </StyledStageWrapper>
  )
}

InfoCardStage.propTypes = {
  className: PropTypes.string,
  infocard: PropTypes.object,
  image: PropTypes.object,
  video: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
      contentType: PropTypes.string,
    }),
  }),
  videoPoster: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
      contentType: PropTypes.string,
    }),
  }),
  disruptor: PropTypes.shape({
    fluid: PropTypes.object,
    link: PropTypes.string,
    description: PropTypes.string,
  }),
  options: PropTypes.object,
}

export default InfoCardStage
