import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'twin.macro'
import Img from 'gatsby-image'

import Tag from '@objects/tag'
import Container from '@objects/container'
import Headline from '@objects/headline'
import Circle3pxHalfVert from '@static/img/tracklines/circles/circle-3px-halfline-vertical.inline.svg'
import breakpoint from '@utils/breakpoint'
import Parallax from '@objects/parallax'

const ArticleInfo = styled.div.attrs({
  className:
    'pt-4 md:pr-6 lg:pr-10 md:pt-0 relative order-2 md:order-1 flex flex-col',
})`
  ${breakpoint('md')`
    justify-content:center;
  `}
  ${breakpoint('lg')`
    div:first-child {
      margin-top: -110px;
    }
  `}
`

function HeadlineImageStage({ className, image, articleInfo, fixed }) {
  const StyledImageWrapper = styled.div.attrs({
    className: 'order-1 z-0 relative md:order-2',
  })`
    margin-left: -16px;
    margin-right: -16px;
    width: 100vw;
    ${breakpoint('md')`
      margin-top: -64px; /* over breadcrumb bar */
      max-width: ${!!fixed ? 'auto' : '680px'};
      margin-left: initial;
      margin-right: initial;
      width: 100%;
    `}
    ${breakpoint('lg')`
      margin-top: -100px; /* over breadcrumb bar */
      margin-right: calc( (100vw - 1072px) / 2 * -1 ) ; /* to screen edge view width minus container width / 2 margins */
    `}
  `

  return (
    <div className={`${className} ${tw`w-full`}`} data-testid="headline-image">
      <Container className={`flex flex-col md:flex-row `} nopadding>
        <ArticleInfo className={'md:w-1/2'}>
          {articleInfo.tag && (
            <Tag className={'mb-7'} size={'xl'}>
              {articleInfo.tag.name}
            </Tag>
          )}
          <Headline
            level={1}
            html={articleInfo.headline}
            ariaLabel={articleInfo.headline}
          />
        </ArticleInfo>
        {image && (
          <StyledImageWrapper fixed={fixed}>
            <Parallax fixed={fixed}>
              <Img
                fluid={{ ...image.fluid, media: `(min-width: 0px)` }}
                alt={image.description}
                backgroundColor={true}
                loading="eager"
              />
            </Parallax>
            <div className={'block md:hidden bottomline'}>
              <Circle3pxHalfVert className={'bottomcircle'} />
            </div>
          </StyledImageWrapper>
        )}
      </Container>
      <div className={'bottomline-wrapper'}>
        <div className={'hidden md:block bottomline'}>
          <Circle3pxHalfVert className={'bottomcircle'} />
        </div>
      </div>
    </div>
  )
}

HeadlineImageStage.propTypes = {
  className: PropTypes.string,
  articleInfo: PropTypes.shape({
    headline: PropTypes.string,
    tag: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  image: PropTypes.object,
  fixed: PropTypes.bool,
}

export default styled(HeadlineImageStage).attrs({
  className: 'relative',
})`
  .bottomline-wrapper {
    position: absolute;
    height: 20px;
    width: 100vw;
    overflow: hidden;
    bottom: -10px;

    .bottomline {
      bottom: 10px;
    }
  }

  .bottomline {
    position: absolute;
    bottom: 0;
    right: 16px;
    width: calc(100vw - 16px);
    height: 3px;
    background: ${({ theme }) => theme.colors.yellow.default};
    z-index: 10;

    .bottomcircle {
      position: absolute;
      right: 0;
      bottom: -10px;
      height: 20px;
      width: 20px;
      line,
      circle {
        stroke: ${({ theme }) => theme.colors.yellow.default};
      }
    }
  }

  ${breakpoint('md')`
    .bottomline {
      left: calc( 50% + 260px );
      width: calc(50% - 260px);
      right: initial;

      .bottomcircle {
        right: initial;
        left: 0;
      }
    }
  `}
`
