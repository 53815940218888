import React from 'react'
import PropTypes from 'prop-types'
import text from '@utils/text'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Headline from '@objects/headline'
import { TeaserCopy } from '@objects/copy'
import Button from '@objects/button'
import useDateFns from '@hooks/useDateFns'
import Tag from '@objects/tag'

const StyledMeta = styled.div`
  ${text('metamd')}
`

function DateCard({ className, headline, copy, date, tag, href, truncate }) {
  const { formatDate } = useDateFns()
  const formattedDate = formatDate(date ?? +new Date())
  const { name: tagName, to: tagTo, filter: tagFilter } = tag

  return (
    <div className={className} data-testid="date">
      <div className="flex justify-between mb-2">
        <Tag className={'flex-auto'} to={tagTo} filter={tagFilter} size={'md'}>
          {tagName}
        </Tag>
        <StyledMeta>{formattedDate}</StyledMeta>
      </div>
      <Link to={href}>
        <Headline level={4} className="mb-2 w-full" ariaLabel={headline}>
          {headline}
        </Headline>
      </Link>
      <TeaserCopy
        className="w-full"
        noHrefArrow={true}
        truncate={truncate}
        truncateextend={<Button textlink link={href} tabIndex={-1} />}
        html={copy}
      />
    </div>
  )
}

DateCard.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.string,
  copy: PropTypes.string,
  truncate: PropTypes.number,
  href: PropTypes.string,
  date: PropTypes.string,
  tag: PropTypes.shape({
    name: PropTypes.string,
    to: PropTypes.string,
    filter: PropTypes.string,
  }),
}

export default DateCard
