import React from 'react'
import PropTypes from 'prop-types'
import useDateFns from '@hooks/useDateFns'
import styled from 'styled-components'
import text from '@utils/text'

import Headline from '@objects/headline'
import { TeaserCopy } from '@objects/copy'
import Button from '@objects/button'
import Tag from '@objects/tag'

const StyledMeta = styled.div`
  ${text('metalg')}
`

function DateButtonCard({
  className,
  headline,
  copy,
  button,
  date,
  dateEnd,
  dateRelative,
  tag,
  location,
  truncate,
}) {
  const { name: tagName, to: tagTo, filter: tagFilter } = tag || {}

  const {
    formatDistanceToNow,
    differeneInDaysFromNow,
    formatDate,
    isSameDay,
  } = useDateFns()

  function renderDate() {
    if (!dateEnd || isSameDay(date, dateEnd)) {
      if (dateRelative && differeneInDaysFromNow(date) <= 7) {
        return formatDistanceToNow(date)
      } else {
        return formatDate(date)
      }
    } else {
      return (
        formatDate(date, 'dd.MM.yyyy - ') + formatDate(dateEnd, 'dd.MM.yyyy')
      )
    }
  }

  return (
    <div className={className} data-testid="datebutton">
      <div className="flex justify-between mb-2">
        {tag && (
          <Tag className={'flex-auto'} to={tagTo} filter={tagFilter} size="lg">
            {tagName}
          </Tag>
        )}
        <StyledMeta>{renderDate()}</StyledMeta>
        {location && <StyledMeta>{location}</StyledMeta>}
      </div>
      <Headline
        level={2}
        className="mb-3 w-full text-xl font-medium leading-8 md:text-3xl md:leading-9"
        ariaLabel={headline}
      >
        {headline}
      </Headline>
      <TeaserCopy
        className="mb-7 w-full"
        noHrefArrow={true}
        truncate={truncate}
        html={copy}
      />
      <Button to={button.to} href={button.href} link={button.link}>
        {button.label}
      </Button>
    </div>
  )
}

DateButtonCard.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.string,
  copy: PropTypes.string,
  button: PropTypes.shape({
    label: PropTypes.string,
    to: PropTypes.string,
    href: PropTypes.string,
    link: PropTypes.string,
  }),
  date: PropTypes.string,
  dateEnd: PropTypes.string,
  dateRelative: PropTypes.bool,
  tag: PropTypes.shape({
    name: PropTypes.string,
    to: PropTypes.string,
    filter: PropTypes.string,
  }),
  location: PropTypes.string,
  truncate: PropTypes.number,
}

export default DateButtonCard

/**
 display: inline-block;
 line-height: 1;
 10px
 * */
